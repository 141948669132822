<div class="content">
    <app-header
        [user]="user"
        [selectableChantiers]="selectableChantiers$ | async"
        [selectedChantier]="selectedChantier$ | async"
        (openSelectChantierModal)="openSelectChantierModal()"
        (logout)="logOut()"
        (selectChantier)="selectChantier($event)"
        (contactSupport)="onContactSupport()"
    />
    <router-outlet />
    <app-footer [backendVersion]="backendVersion$ | async" />
</div>
