import { Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgSelectModule } from '@ng-select/ng-select'
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { ChantierDomainService } from '../../../shared/domain/chantier.domain'
import { Router } from '@angular/router'
import { debounceTime, distinctUntilChanged, filter, Subject, switchMap, tap } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { UserDomainService } from '../../../shared/domain/user.domain'
import { CoreModuleDomainService } from '../../domain/core-module.domain'

@Component({
    selector: 'app-select-other-chantier-modal',
    standalone: true,
    imports: [CommonModule, NgSelectModule, ReactiveFormsModule],
    templateUrl: './select-other-chantier-modal.component.html',
    styleUrls: ['./select-other-chantier-modal.component.scss'],
})
export class SelectOtherChantierModalComponent {
    fb = inject(FormBuilder)
    chantierDomainService = inject(ChantierDomainService)
    userDomainService = inject(UserDomainService)
    router = inject(Router)
    toastr = inject(ToastrService)
    modalService = inject(NgbModal)
    coreModuleDomainService = inject(CoreModuleDomainService)

    lastChantiersSearchKeywords: string
    selectOtherChantierForm: FormGroup

    userIsSuperAdmin: boolean

    // Affichage résultat recherche
    chantierSearchInProgress = false
    chantierIsParametre$ = this.coreModuleDomainService.chantierIsParametre$
    emailsAdminsChantier$ = this.coreModuleDomainService.emailsAdminChantier$

    // Recherche de nouveau chantiers
    chantiersSearchedInModal$ = this.coreModuleDomainService.searchedChantiers$
    chantierSelectedInModal$ = this.coreModuleDomainService.selectedChantier$
    chantiersSearchKeyword$ = new Subject<string>()
    chantiersSearchLoading = false
    nextChantierSearchPage: number = 0
    protected readonly console = console

    constructor() {
        this.userIsSuperAdmin = this.userDomainService.userIsSuperAdmin()
        this.selectOtherChantierForm = this.fb.group({
            chantierSearchField: [null, [Validators.required]],
        })
        this.chantiersSearchKeyword$
            .pipe(
                debounceTime(100),
                distinctUntilChanged(),
                filter((keywords) => keywords?.length >= 3),
                tap(() => {
                    this.chantiersSearchLoading = true
                }),
                switchMap((keywords) => this.coreModuleDomainService.findChantiers(keywords, 0)),
            )
            .subscribe({
                next: () => {
                    this.chantiersSearchLoading = false
                },
                error: (err) => {
                    this.toastr.error('Une erreur est survenue lors de la recherche du chantier')
                    console.error(err)
                },
            })
    }

    onChantierChanged(): void {
        const codeChantier: string = this.selectOtherChantierForm.get('chantierSearchField').value
        if (codeChantier) {
            this.toggleChantierSearchInProgress(true)
            if (
                this.chantierDomainService.chantierIsSelected(codeChantier) ||
                this.chantierDomainService.chantierIsSelectable(codeChantier)
            ) {
                this.onCloseSelectOtherChantierModale()
                this.chantierDomainService.setSelectedChantier(codeChantier)
                void this.router.navigate(['/chantiers', codeChantier])
                return
            }

            this.coreModuleDomainService
                .selectChantierInModal(codeChantier)
                .subscribe(() => this.toggleChantierSearchInProgress(false))
        }
    }

    onChantiersSearchChanged(keywords: string): void {
        this.lastChantiersSearchKeywords = keywords
        this.chantiersSearchKeyword$.next(keywords)
    }

    onChantiersScrollToEnd(): void {
        this.chantiersSearchLoading = true
        this.nextChantierSearchPage = this.nextChantierSearchPage + 1

        this.coreModuleDomainService
            .findNewChantiersPage(this.lastChantiersSearchKeywords, this.nextChantierSearchPage)
            .subscribe({
                next: () => {
                    this.chantiersSearchLoading = false
                },
                error: (err) => {
                    this.toastr.error('Une erreur est survenue lors de la recherche du collaborateur')
                    console.error(err)
                },
            })
    }

    onCloseSelectOtherChantierModale(): void {
        this.coreModuleDomainService.resetState()
        this.selectOtherChantierForm.reset({ chantierSearchField: null })
        this.chantiersSearchKeyword$.next('')
        this.nextChantierSearchPage = 0
        this.chantiersSearchLoading = false
        this.lastChantiersSearchKeywords = null
        this.chantierSearchInProgress = false
        this.toggleChantierSearchInProgress(false)
        this.modalService.dismissAll()
    }

    toggleChantierSearchInProgress(searchInProgress: boolean): void {
        this.chantierSearchInProgress = searchInProgress
        searchInProgress ? this.selectOtherChantierForm.disable() : this.selectOtherChantierForm.enable()
    }

    openLinkInNewTab(link: string): void {
        window.open(link, '_blank')
    }

    onAccesAdminClick(): void {
        const chantierToAccess = this.coreModuleDomainService.getSelectedChantier()
        this.coreModuleDomainService.addCurrentChantierToUserChantiers()
        this.chantierDomainService.setSelectedChantier(chantierToAccess.code)
        this.coreModuleDomainService.resetState()
        this.modalService.dismissAll()
        this.router.navigate(['/chantiers', chantierToAccess.code])
    }
}
