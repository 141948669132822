import { Component } from '@angular/core'
import { NgForOf, NgOptimizedImage } from '@angular/common'

@Component({
    selector: 'app-catalogue-service-materiel-portlet-body',
    standalone: true,
    imports: [NgForOf, NgOptimizedImage],
    templateUrl: './catalogue-service-materiel-portlet-body.component.html',
    styleUrl: './catalogue-service-materiel-portlet-body.component.scss',
})
export class CatalogueServiceMaterielPortletBodyComponent {
    imgUrl = 'assets/images/home/catalogue-service-materiel-body.jpg'
}
