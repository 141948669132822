<div class="container py-0 px-0 h-100 mb-3">
    <div class="row">
        <div *ngIf="userIsAdminChantier$ | async">
            <p>
                Votre rôle d'administrateur de chantier vous permet de gérer les droits des compagnons sur votre
                chantier.
            </p>
            <p class="mb-0">Pour tout changement de droit vous pouvez :</p>
            <ol>
                <li>Accéder à l'application Pointage Chantier via le lien ci-dessous</li>
                <li>Puis dans "Accueil" cliquez sur "Éditer les affectations" en haut à droite</li>
            </ol>
            <p class="mb-1">En cas de problème, vous pouvez solliciter le support DSI à l'adresse suivante :</p>
            <div class="ps-2 row">
                <div class="col-1 d-flex align-items-center justify-content-end pe-2">
                    <i class="bi bi-envelope red fs-4"></i>
                </div>
                <div class="col-10 d-flex align-items-center justify-content-start ps-2">
                    <div class="fw-semibold">applications.chantiers&#64;leongrosse.fr</div>
                </div>
            </div>
        </div>
        <div *ngIf="(userIsAdminChantier$ | async) === false">
            <ng-container *ngLet="emailsAdminsChantier$ | async as emailsAdminsChantier">
                <p class="mb-1">Pour tout changement, veuillez contacter les administrateurs suivants :</p>
                <div
                    *ngIf="emailsAdminsChantier"
                    [ngClass]="emailsAdminsChantier.length < 3 ? 'row-cols-1' : 'row-cols-2'"
                    class="row ps-4 pb-3"
                >
                    <div *ngFor="let email of emailsAdminsChantier" class="col">
                        <div class="row">
                            <div class="col-1 d-flex align-items-center justify-content-end pe-2">
                                <i class="bi bi-envelope red fs-4"></i>
                            </div>
                            <div class="col-10 d-flex align-items-center justify-content-start ps-2">
                                <a
                                    class="fw-semibold text-truncate text-black"
                                    href="mailto:{{ email }}"
                                    target="_blank"
                                    title="{{ email }}"
                                    >{{ email }}</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-1 d-flex align-items-center justify-content-end pe-2">
                            <i class="bi bi-envelope red fs-4"></i>
                        </div>
                        <div class="col-10 d-flex align-items-center justify-content-start ps-2">
                            <a
                                class="fw-semibold text-truncate text-black"
                                href="mailto:applications.chantiers@leongrosse.fr"
                                target="_blank"
                                title="applications.chantiers@leongrosse.fr"
                                >applications.chantiers&#64;leongrosse.fr
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
