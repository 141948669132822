import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core'
import packageJson from '../../../../../package.json'
import { combineLatestWith, filter, map, mergeWith, Observable, of } from 'rxjs'
import { AsyncPipe, NgIf } from '@angular/common'
import { ParamDomainService } from '../../../shared/domain/param.domain'
import { ParamCodeEnum } from '../../../shared/models/enums/param-code.enum'
import { ChantierDomainService } from '../../../shared/domain/chantier.domain'
import { ContactCsm } from '../../../shared/models/contact-csm'
import { NgLetDirective } from '../../../shared/directives/ng-let.directive'
import { NavigationEnd, Router } from '@angular/router'

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe, NgLetDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
    @Input({ required: true })
    backendVersion: string
    private readonly paramDomainService
    private readonly chantierDomainService
    private readonly router = inject(Router)
    contactCsm$: Observable<ContactCsm>
    shouldDisplayContactCSM$: Observable<boolean>
    version = packageJson.version
    contactEmail$: Observable<string>

    constructor() {
        this.paramDomainService = inject(ParamDomainService)
        this.chantierDomainService = inject(ChantierDomainService)
        this.contactCsm$ = this.chantierDomainService.selectedChantierCSM$

        const userIsOnHomepage$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            mergeWith(of(true)),
            map(() => this.router.url.split('/')[1] === 'chantiers'),
        )
        this.shouldDisplayContactCSM$ = this.contactCsm$.pipe(
            combineLatestWith(userIsOnHomepage$),
            map(([contactCsm, userIsOnHomepage]) => userIsOnHomepage && !!contactCsm?.nom && !!contactCsm?.telephone),
        )
        this.contactEmail$ = this.paramDomainService.params$.pipe(
            map((params) => {
                return params?.find((param) => param.codeParam === ParamCodeEnum.footer_contact_mail)?.valeurParam
            }),
        )
    }
}
