<div class="modal-header">
    <h4 class="modal-title">Accéder à un chantier</h4>
    <button (click)="onCloseSelectOtherChantierModale()" aria-label="Close" class="btn-close" type="button"></button>
</div>
<div class="modal-body">
    <div class="row">
        <form [formGroup]="selectOtherChantierForm">
            <div class="mb-3">
                <ng-select
                    (change)="onChantierChanged()"
                    (scrollToEnd)="onChantiersScrollToEnd()"
                    (search)="onChantiersSearchChanged($event.term)"
                    [clearable]="false"
                    [closeOnSelect]="true"
                    [loading]="chantiersSearchLoading"
                    [typeahead]="chantiersSearchKeyword$"
                    [virtualScroll]="true"
                    formControlName="chantierSearchField"
                    id="chantierSearchField"
                    notFoundText="Aucun chantier trouvé"
                    placeholder="Chantier"
                    typeToSearchText="Rechercher un chantier"
                >
                    <ng-option *ngFor="let chantier of chantiersSearchedInModal$ | async" [value]="chantier.code">
                        <span [title]="chantier.code + ' - ' + chantier.nom">{{
                            chantier.code + " - " + chantier.nom
                        }}</span>
                    </ng-option>
                </ng-select>
            </div>
        </form>
    </div>
    <div *ngIf="chantierSearchInProgress" class="row pt-2 h-100 align-items-center">
        <p class="card-text placeholder-wave">
            <span class="placeholder col-4"></span>&nbsp; <span class="placeholder col-6"></span>&nbsp;
            <span class="placeholder col-3"></span>&nbsp; <span class="placeholder col-5"></span>&nbsp;
            <span class="col-12 placeholder bg-white"></span>
            <span class="placeholder col-5"></span>&nbsp; <span class="placeholder col-3"></span>&nbsp;
            <span class="placeholder col-6"></span>&nbsp; <span class="placeholder col-4"></span>&nbsp;
        </p>
    </div>
    <div *ngIf="!chantierSearchInProgress && chantierSelectedInModal$ | async" class="row">
        <div *ngIf="(chantierIsParametre$ | async) === false" class="row ps-4">
            <p class="px-0 py-2 m-0">
                Ce chantier n'est pas encore paramétré.<br /><br />
                Vous devez effectuez une demande d'ouverture chantier dans l'application via le formulaire suivant :
            </p>
        </div>
        <div
            *ngIf="(chantierIsParametre$ | async) === true && (emailsAdminsChantier$ | async)?.length > 0"
            class="row ps-4 pb-3"
        >
            <p class="px-0 pb-3 m-0">
                Les droits d'accès au chantier sont gérés par les personnes suivantes (administrateurs du chantier) :
            </p>
            <div *ngFor="let email of emailsAdminsChantier$ | async">
                <div class="row">
                    <div class="col-1 d-flex align-items-center justify-content-end pe-2">
                        <i class="bi bi-envelope red fs-4"></i>
                    </div>
                    <div class="col-10 d-flex align-items-center justify-content-start ps-2">
                        <a
                            class="fw-semibold text-truncate text-black"
                            href="mailto:{{ email }}"
                            target="_blank"
                            title="{{ email }}"
                            >{{ email }}</a
                        >
                    </div>
                </div>
            </div>
            <p class="px-0 pt-3 m-0">
                Veuillez contacter l'une de ces personnes pour qu'elle vous affecte les droits (cf. la documentation
                suivante).
            </p>
            <p class="text-muted fw-lighter user-select-none px-0 pt-4 m-0" style="font-size: 0.8rem">
                En cas de problème, merci de contacter le support de l'application via l'adresse
                <a
                    class="fw-light text-truncate text-black"
                    href="mailto:applications.chantiers&#64;leongrosse.fr"
                    target="_blank"
                    title="applications.chantiers&#64;leongrosse.fr"
                >
                    applications.chantiers&#64;leongrosse.fr
                </a>
            </p>
        </div>
        <div
            *ngIf="(chantierIsParametre$ | async) === true && (emailsAdminsChantier$ | async)?.length === 0"
            class="row ps-4"
        >
            <p class="px-0 py-2 m-0">
                Merci de contacter le support de l'application via l'adresse
                <a
                    class="text-truncate fw-bold"
                    href="mailto:applications.chantiers&#64;leongrosse.fr"
                    target="_blank"
                    title="applications.chantiers&#64;leongrosse.fr"
                    >applications.chantiers&#64;leongrosse.fr</a
                >
            </p>
        </div>
    </div>
</div>
<div class="modal-footer d-flex flex-column align-items-stretch">
    <button
        (click)="
            openLinkInNewTab(
                'https://docs.google.com/forms/d/e/1FAIpQLScTibAl0gs8hvJXEk-uttaFsSVs9biroCrxN7fdEUOAEWc4Iw/viewform'
            )
        "
        *ngIf="
            !chantierSearchInProgress && (chantierIsParametre$ | async) === false && chantierSelectedInModal$ | async
        "
        class="btn btn-outline-primary"
        type="button"
    >
        <span> Formulaire </span>
    </button>
    <button
        (click)="
            openLinkInNewTab('https://sites.google.com/leongrosse.fr/pointage-chantiers/dsi/pco-360/gestion-des-droits')
        "
        *ngIf="
            !chantierSearchInProgress &&
                (chantierIsParametre$ | async) &&
                (emailsAdminsChantier$ | async)?.length > 0 &&
                chantierSelectedInModal$ | async
        "
        class="btn btn-outline-primary"
        type="button"
    >
        <span> Documentation </span>
    </button>
    <button
        (click)="onAccesAdminClick()"
        *ngIf="!chantierSearchInProgress && userIsSuperAdmin && chantierSelectedInModal$ | async"
        class="btn btn-primary"
        type="button"
    >
        <span> Accès Super Admin </span>
    </button>
    <button (click)="onCloseSelectOtherChantierModale()" class="btn btn-outline-secondary" type="button">Fermer</button>
</div>
