import { inject, Injectable } from '@angular/core'
import { ChantierState } from '../data-access/state/chantier.state'
import { ChantierDTO } from '../data-access/http/dto/chantier-dto'
import { UserState } from '../data-access/state/user.state'
import { combineLatestWith, map, Observable, switchMap, tap } from 'rxjs'
import { ChantierHttpService } from '../data-access/http/chantier-http.service'
import { ApplicationDomainService } from '../../home/domain/application.domain'
import { Location } from '@angular/common'
import { ContactCsm } from '../models/contact-csm'

@Injectable({ providedIn: 'root' })
export class ChantierDomainService {
    private readonly chantierState = inject(ChantierState)
    private readonly location = inject(Location)
    private readonly chantierHttpService = inject(ChantierHttpService)
    private applicationDomainService = inject(ApplicationDomainService)
    private userState = inject(UserState)

    // Selectors
    selectedChantier$ = this.chantierState.store.select(ChantierState.getSelectedChantier)
    selectedChantierPhotoUrl$ = this.chantierState.store.select(ChantierState.getSelectedChantierPhotoUrl)
    selectableChantiers$ = this.userState.store.select(UserState.getUserChantiers).pipe(
        combineLatestWith(this.chantierState.store.select(ChantierState.getSelectedChantier)),
        map(([chantiers, selectedChantier]) =>
            chantiers.filter((chantier) => chantier.code !== selectedChantier?.code),
        ),
    )
    selectedChantierAdmins$ = this.chantierState.store.select(ChantierState.getSelectedChantierAdmins)
    selectedChantierCSM$: Observable<ContactCsm> = this.chantierState.store.select(ChantierState.getSelectedChantierCSM)
    userIsAdminChantier$: Observable<boolean> = this.chantierState.store
        .select(ChantierState.getSelectedChantier)
        .pipe(map((selectedChantier) => selectedChantier.admin))

    selectedChantierCode(): string {
        return this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode)
    }

    chantierIsSelectable(chantierCode: string): boolean {
        return this.userState.store
            .selectSnapshot(UserState.getUserChantiers)
            .some((chantier) => chantier.code === chantierCode)
    }

    chantierIsSelected(chantierCode: string): boolean {
        return this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode) === chantierCode
    }

    // Actions
    setSelectedChantier(chantierCode: string): void {
        const selectedChantier = this.userState.store
            .selectSnapshot(UserState.getUserChantiers)
            .find((chantier) => chantier.code === chantierCode)
        if (selectedChantier) {
            this.chantierState.store.update((state) => ({ ...state, selectedChantier }))
            this.location.replaceState('/chantiers/' + selectedChantier.code)
            this.applicationDomainService.loadApplicationsOutils()
            this.applicationDomainService
                .loadApplications()
                .pipe(switchMap(() => this.applicationDomainService.loadApplicationsData()))
                .subscribe()
            this.chantierHttpService
                .getChantierAdminEmails(selectedChantier.code, 4)
                .pipe(
                    tap((selectedChantierAdmins) => {
                        this.chantierState.store.update((state) => ({ ...state, selectedChantierAdmins }))
                    }),
                )
                .subscribe()
            this.loadPhotoSelectedChantier().subscribe()
        }
    }

    loadPhotoSelectedChantier(): Observable<ChantierDTO> {
        const selectedChantierCode = this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode)
        return this.chantierHttpService
            .getChantierWithPhoto(selectedChantierCode)
            .pipe(
                tap((chantier) =>
                    this.chantierState.store.update((state) => ({ ...state, selectedChantier: chantier })),
                ),
            )
    }

    addPhotoToSelectedChantier(fileName: string, lambdaId: string): Observable<ChantierDTO> {
        const selectedChantierCode = this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode)
        return this.chantierHttpService
            .setChantierPhoto({ codeChantier: selectedChantierCode, fileName, lambdaId })
            .pipe(
                tap((chantierDto) => {
                    const { urlPhotoChantier, auteurPhotoChantier } = chantierDto
                    if (urlPhotoChantier) {
                        this.chantierState.store.update((state) => ({
                            ...state,
                            selectedChantier: { ...state.selectedChantier, urlPhotoChantier, auteurPhotoChantier },
                        }))
                    }
                }),
            )
    }

    deletePhotoSelectedChantier(): Observable<string> {
        const selectedChantierCode = this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode)
        return this.chantierHttpService.deleteChantierPhoto(selectedChantierCode).pipe(
            tap(() => {
                this.chantierState.store.update((selectedChantier) => ({
                    ...selectedChantier,
                    auteurPhotoChantier: null,
                    urlPhotoChantier: null,
                }))
            }),
        )
    }
}
