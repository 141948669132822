import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { NgbModalConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap'
import { OAuthService } from 'angular-oauth2-oidc'
import { authConfig } from './core/configuration/auth.config'
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks'
import { registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/fr'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    constructor(configModal: NgbModalConfig, configTooltip: NgbTooltipConfig, oauthService: OAuthService) {
        configModal.centered = true
        configModal.backdrop = 'static'

        configTooltip.container = 'body'
        configTooltip.openDelay = 600

        oauthService.configure(authConfig)
        oauthService.tokenValidationHandler = new JwksValidationHandler()
        oauthService.setStorage(localStorage)
        registerLocaleData(localeFr)
    }
    ngOnInit(): void {
        this.removeStartupLoader()
    }

    removeStartupLoader(): void {
        const startupLoader: HTMLElement | null = document.getElementById('startup-loader')
        if (startupLoader) {
            startupLoader.remove()
        }
    }
}
