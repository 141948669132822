<p>
    <span class="h2 lgRed">1.</span> Se connecter au
    <img ngSrc="assets/images/logos/WIFI-black.svg" width="50" height="32" alt="wifi" /> LGGUEST
</p>
<p><span class="h2 lgRed">2.</span> Renseigner le code</p>
<div class="text-center">
    <div class="h5 fw-bold">Longue durée (accès 1 an) :</div>
    <div>{{ codeWifiLong }}</div>
    <div class="h5 fw-bold">&nbsp;&nbsp;&nbsp;Courte durée (accès 3 mois) :</div>
    <div>{{ codeWifiCourt }}</div>
</div>
<p class="m-0"><span class="h2 lgRed">3.</span> Cliquer sur "Se connecter"</p>
<div class="row">
    <div class="offset-7 col-5 text-center">
        <p class="small">Un problème ? Contactez le 4040 ou le 01 87 15 38 13</p>
    </div>
</div>
