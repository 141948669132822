import { Component, Inject, Input, OnInit } from '@angular/core'
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { DatePipe, JsonPipe, NgFor, NgIf, SlicePipe } from '@angular/common'
import {
    InfosPlanningLivraison,
    InfosPlanningLivraisonErreur,
    InfosPlanningLivraisonSuccess,
} from '../../../../shared/models/infos-planning-livraison'
import { InfosPlanningLivraisonDto } from '../../../data-access/http/dto/infos-planning-livraison.dto'
import { DisplayDatePipe } from '../../../../shared/pipes/display-date.pipe'
import { ApplicationStatusEnum } from '../../../../shared/models/enums/application-status.enum'
import { isTodayOrLater, parseCustomDate } from '../../../../shared/utils/date.utils'

@Component({
    selector: 'app-planning-livraison-portlet-body',
    templateUrl: './planning-livraison-portlet-body.component.html',
    styleUrls: ['./planning-livraison-portlet-body.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgbPagination, SlicePipe, DatePipe, DisplayDatePipe, JsonPipe],
})
export class PlanningLivraisonPortletBodyComponent implements OnInit {
    @Input() data: InfosPlanningLivraison
    applicationStatusEnum = ApplicationStatusEnum

    get dataSuccess(): InfosPlanningLivraisonSuccess {
        return this.data as InfosPlanningLivraisonSuccess
    }

    get dataError(): InfosPlanningLivraisonErreur {
        return this.data as InfosPlanningLivraisonErreur
    }
    constructor(
        @Inject('DATA') private dataInjected: string,
        @Inject('STATUS') public status: ApplicationStatusEnum,
        public toastr: ToastrService,
    ) {}

    ngOnInit(): void {
        const infosPlanningLivraisonDto: InfosPlanningLivraisonDto = JSON.parse(this.dataInjected)

        const infosPlanningLivraison: InfosPlanningLivraison =
            PlanningLivraisonPortletBodyComponent.mapDtoToModel(infosPlanningLivraisonDto)
        this.data = this.data || infosPlanningLivraison
    }

    // TODO: This method should not be in the component
    //  It should be performed in application domain but not easy right now
    private static mapDtoToModel(infosPlanningLivraisonDto: InfosPlanningLivraisonDto): InfosPlanningLivraison {
        if (!infosPlanningLivraisonDto) {
            return null
        }
        if (infosPlanningLivraisonDto.erreur) {
            return { erreur: infosPlanningLivraisonDto.erreur }
        }
        return {
            ...infosPlanningLivraisonDto,
            dateDerniereDemande: parseCustomDate(infosPlanningLivraisonDto.dateDerniereDemande),
            livraisons: [
                ...infosPlanningLivraisonDto.livraisons
                    .map((livraison) => ({
                        ...livraison,
                        dateLivraison: parseCustomDate(livraison.dateLivraison),
                    }))
                    .filter((livraison) => isTodayOrLater(livraison.dateLivraison)),
            ],
        }
    }
}
