import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-widget',
  standalone: true,
  imports: [],
  templateUrl: './contact-widget.component.html',
  styleUrl: './contact-widget.component.scss'
})
export class ContactWidgetComponent {

}
