import { inject, Injectable } from '@angular/core'
import { CoreModuleState, CoreModuleStateModel } from '../data-access/state/core-module.state'
import { ChantierHttpService } from '../../shared/data-access/http/chantier-http.service'
import { Observable, of, switchMap, tap } from 'rxjs'
import { PageResponse } from '../../shared/data-access/http/dto/page-dto'
import { ChantierDTO } from '../../shared/data-access/http/dto/chantier-dto'
import { UserState } from '../../shared/data-access/state/user.state'

@Injectable({ providedIn: 'root' })
export class CoreModuleDomainService {
    private readonly coreModuleState = inject(CoreModuleState)
    private readonly chantierHttpService = inject(ChantierHttpService)
    private readonly userState = inject(UserState)
    // Selectors
    searchedChantiers$ = this.coreModuleState.store.select(CoreModuleState.getSearchedChantiers)
    emailsAdminChantier$ = this.coreModuleState.store.select(CoreModuleState.getChantierAdminEmails)
    chantierIsParametre$ = this.coreModuleState.store.select(CoreModuleState.getIsChantierParametre)
    selectedChantier$ = this.coreModuleState.store.select(CoreModuleState.getSelectedChantier)

    getSelectedChantier(): ChantierDTO {
        return this.coreModuleState.store.selectSnapshot(CoreModuleState.getSelectedChantier)
    }

    // Actions

    addCurrentChantierToUserChantiers(): void {
        const user = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const selectedChantier = this.coreModuleState.store.selectSnapshot(CoreModuleState.getSelectedChantier)
        if (user.chantiers.map((c) => c.code).includes(selectedChantier.code)) {
            return
        }
        const newUser = {
            ...user,
            chantiers: [
                ...user.chantiers,
                this.coreModuleState.store.selectSnapshot(CoreModuleState.getSelectedChantier),
            ],
        }
        this.userState.store.update(() => newUser)
    }
    findChantiers(keywords: string, page: number): Observable<PageResponse<ChantierDTO>> {
        return this.chantierHttpService
            .findChantiers(keywords, page)
            .pipe(
                tap((chantiers) =>
                    this.coreModuleState.store.update((state) => ({ ...state, searchedChantiers: chantiers.content })),
                ),
            )
    }

    findNewChantiersPage(keywords: string, pageNum: number): Observable<PageResponse<ChantierDTO>> {
        return this.chantierHttpService.findChantiers(keywords, pageNum).pipe(
            tap((chantiers) =>
                this.coreModuleState.store.update((state: CoreModuleStateModel) => ({
                    ...state,
                    searchedChantiers: [...state.searchedChantiers, ...chantiers.content].sort((a, b) =>
                        a.code.localeCompare(b.code),
                    ),
                })),
            ),
        )
    }

    private checkParametrageChantier(codeChantier: string): Observable<boolean> {
        return this.chantierHttpService.isChantierParametre(codeChantier).pipe(
            tap((isChantierParametre) =>
                this.coreModuleState.store.update((state: CoreModuleStateModel) => ({
                    ...state,
                    isChantierParametre,
                })),
            ),
        )
    }

    private findChantierAdminsEmails(codeChantier: string, size: number): Observable<string[]> {
        return this.chantierHttpService.getChantierAdminEmails(codeChantier, size).pipe(
            tap((chantierAdminEmails) =>
                this.coreModuleState.store.update((state: CoreModuleStateModel) => ({
                    ...state,
                    chantierAdminEmails,
                })),
            ),
        )
    }

    selectChantierInModal(chantierCode: string): Observable<string[]> {
        const searchedChantiers = this.coreModuleState.store.selectSnapshot(CoreModuleState.getSearchedChantiers)
        const selectedChantier = searchedChantiers.find((chantier) => chantier.code === chantierCode)
        this.coreModuleState.store.update((state) => ({
            ...state,
            selectedChantier,
        }))
        return this.checkParametrageChantier(chantierCode).pipe(
            switchMap((chantierIsParametre) => {
                if (chantierIsParametre) {
                    return this.findChantierAdminsEmails(chantierCode, 4)
                }
                return of(null)
            }),
        )
    }

    resetState(): void {
        this.coreModuleState.store.update(() => ({
            selectedChantier: null,
            chantierAdminEmails: [],
            isChantierParametre: false,
            searchedChantiers: [],
        }))
    }
}
