import { Component, Input } from '@angular/core'
import { User } from '../../../../shared/models/user'
import { RouterLink } from '@angular/router'

@Component({
    selector: 'app-logo-display',
    standalone: true,
    imports: [RouterLink],
    templateUrl: './logo-display.component.html',
    styleUrl: './logo-display.component.scss',
})
export class LogoDisplayComponent {
    @Input({ required: true })
    user: User
}
