import { Component } from '@angular/core'
import { NgForOf } from '@angular/common'

@Component({
    selector: 'app-booster-box-portlet-body',
    standalone: true,
    imports: [NgForOf],
    templateUrl: './booster-box-portlet-body.component.html',
    styleUrl: './booster-box-portlet-body.component.scss',
})
export class BoosterBoxPortletBodyComponent {
    protected readonly boosterBoxLinks: BoosterBoxLink[] = [
        {
            label: 'Suivi des accès chantier',
            url: 'https://docs.google.com/document/d/1gybr3b4P0zHQCB1sNF6OOZyJ1b_szY8lmZP7ohgTeB0/edit#heading=h.gjdgxs',
        },
        {
            label: 'Panneau de chantier connecté',
            url: 'https://docs.google.com/document/d/1efIlg1o2aYPtVNIQOr8dEs1Fd2NYfvcEByG53XVZETE/edit',
        },
    ]
}

interface BoosterBoxLink {
    label: string
    url: string
}
