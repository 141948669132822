import { Routes } from '@angular/router'
import { AppLayoutComponent } from './core/feature/app-layout/app-layout.component'
import { AuthGuard } from './core/guards/auth.guard'
import { NotFoundComponent } from './core/feature/not-found/not-found.component'
import { UserNotAuthenticatedComponent } from './core/feature/user-not-authenticated/user-not-authenticated.component'
import { NoChantierComponent } from './core/feature/no-chantier/no-chantier.component'
import { globalResolver } from './shared/resolvers/global.resolver'

export const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        resolve: { params: globalResolver },
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadChildren: () => import('./home/feature/home.routes').then((m) => m.HOME_ROUTES),
            },
            {
                path: 'chantiers/:chantierCode',
                loadChildren: () => import('./home/feature/home.routes').then((m) => m.HOME_ROUTES),
            },
            {
                path: 'admin',
                loadChildren: () => import('./admin/feature/admin.routes').then((m) => m.ADMIN_ROUTES),
            },
            {
                path: 'admin-import-beton',
                loadChildren: () =>
                    import('./admin-import-beton/feature/admin-import-beton.routes').then(
                        (m) => m.ADMIN_IMPORT_BETON_ROUTES,
                    ),
            },
            {
                path: 'droitsrh',
                loadChildren: () => import('./droitsrh/feature/droitsrh.routes').then((m) => m.DROITSRH_ROUTES),
            },
            {
                path: 'accueil-chantier',
                loadChildren: () =>
                    import('./accueil-chantier/feature/accueil-chantier.routes').then((m) => m.ACCUEIL_CHANTIER_ROUTES),
            },
            { path: 'not-found', component: NotFoundComponent },
            { path: 'user-not-authenticated', component: UserNotAuthenticatedComponent },
            { path: 'no-chantier', component: NoChantierComponent },
        ],
    },
    { path: '**', redirectTo: 'not-found' },
]
